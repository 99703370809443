<template>
  <div>
    <b-card header-tag="header" footer-tag="footer">
      <b-card bg-variant="primary" style="margin-bottom: 5px;" no-body>
        <b-card-header header-bg-variant="white" header-text-variant="dark" class="p-1">
          Veri Tabanı Döküman Yönetim Ekranı
          <b-button class="pull-right" size="sm" variant="primary" @click="d_showMode ? d_showMode = false : d_showMode = true"><i class="fa fa-edit"></i></b-button>
        </b-card-header>
        <b-row style="padding: 3px;" v-if="d_showMode">
          <b-col sm="12" md="12">
            <b-card no-body style="color: black; height: 100%; margin-bottom: 0px;">
              <b-card-header header-bg-variant="white" header-text-variant="dark" class="p-1">
                Sorgulama İşlemi
              </b-card-header>
              <b-row style="padding: 3px;">
                <b-col sm="12" lg="2">
                  Dökümanları İndir
                </b-col>
                <b-col sm="12" lg="1">
                  <b-form-checkbox v-model="d_searchingData.download"></b-form-checkbox>
                </b-col>
                <b-col sm="12" lg="3">
                  <b-form-input v-model="d_searchingData.unit_name" placeholder="birim ör: default"></b-form-input>
                </b-col>
                <b-col sm="12" lg="5">
                  <b-card v-if="d_searchingData.type === 'get_with_key'" no-body>
                    <b-form-input v-model="d_searchingData.key" @keydown.enter.native="ClickEnter" placeholder="doküman key değerini yazınız"></b-form-input>
                  </b-card>
                </b-col>
                <b-col sm="12" lg="1">
                  <b-button size="md" variant="primary" @click="f_getSearchList()"><i class="fa fa-update"></i>getir</b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-card>
      <b-card-header header-bg-variant="white" header-text-variant="dark" class="p-1">
        <b-row style="padding: 3px;">
          <b-col sm="12" md="8">
            Dokümen detayları
          </b-col>
          <b-col sm="12" md="4" style="text-align: right;">
            <button v-if="d_searchingData.data" type="button" class="btn btn-primary" style="margin-right: 5px;" v-on:click="f_openUpsertModal('edit')">Doküman düzenle</button>
            <button type="button" class="btn btn-primary" style="margin-right: 5px;" v-on:click="f_openUpsertModal('new')">Doküman ekle</button>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card v-if="d_searchingData.data" style="margin-top: 10px; margin-bottom: 0px;" no-body>
        <b-card-header header-bg-variant="white" header-text-variant="dark" class="p-1">
          Sorgu Sonucu
        </b-card-header>
        <b-row style="margin: 0px;">
          <b-col sm="12" md="12">
            <div style="overflow-y: auto; height: 300px; white-space: pre;">
              {{ f_getJsonStringify(d_searchingData.data) }}
            </div>
          </b-col>
        </b-row>
      </b-card>
    </b-card>
    <b-modal v-if="d_editData.show" v-model="d_editData.show" size="md" title="Veri düzenleme" centered scrollable hide-footer header-bg-variant="info">
      <b-card no-body>
        <b-form-input v-model="d_editData.key" placeholder="doküman key değerini yazınız"></b-form-input>
      </b-card>
      <b-card no-body>
        <b-form-input v-model="d_editData.unit_name" placeholder="doküman birimini yazınız"></b-form-input>
      </b-card>
      <b-card no-body>
        <b-textarea v-model="d_editData.data" rows="10" width="100%"></b-textarea>
      </b-card>
      <b-row>
        <b-col cols="12" style="text-align: right;">
          <button type="button" class="btn btn-success" v-on:click="f_upsertData()">Kaydet</button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  default as Modal
} from '@/components/widgets/Modal';
import WmanagerService from '@/services/wmanager';
import FileSaver from 'file-saver';

export default {
  name: 'db_manager',
  computed: {
    ...mapGetters({
      lang: 'lang',
      help: 'help'
    })
  },
  data () {
    return {
      d_searchingData: {
        'download': 0,
        'type': 'get_with_key',
        'key': '',
        'query_string': '',
        'unit_name': '',
        'data': ''
      },
      d_editData: {
        'show': false,
        'data': '',
        'key': '',
        'unit_name': ''
      },
      user: {},
      d_showMode: true,
    };
  },
  created: function () {
    this.user = JSON.parse(localStorage.getItem('user'));
  },
  mounted: function () {},
  methods: {
    f_openUpsertModal (type) {
      if (type === 'new') {
        this.d_editData.data = '';
        this.d_editData.key = '';
        this.d_editData.unit_name = '';
      } else if (type === 'edit') {
        this.d_editData.data = this.d_searchingData.data;
        this.d_editData.key = this.d_searchingData.key;
        this.d_editData.unit_name = this.d_searchingData.unit_name;
      }
      this.d_editData.show = true;
    },
    ClickEnter (event) {
      if (event.which === 13) {
        this.f_getSearchList();
      }
    },
    f_getJsonStringify: function (data) {
      return JSON.stringify(data, null, 4);
    },
    f_getSearchList: function () {
      this.d_searchingData.data = '';
      // console.log('this.d_searchingData ', this.d_searchingData);
      if (this.d_searchingData.type === 'get_with_key' && !this.d_searchingData.key) {
        alert('Lütfen getirmek istediğiniz dökümana ait key değerini giriniz.');
        return;
      } else if (this.d_searchingData.type === 'query_string' && !this.d_searchingData.query_string) {
        alert('Lütfen getirmek istediğiniz dökümana ait query stringi giriniz.');
        return;
      }
      if (this.d_searchingData.type === 'get_with_key') {
        if (this.d_searchingData.unit_name && this.d_searchingData.key) {
          let data = {
            "key": this.d_searchingData.key,
            "unit": this.d_searchingData.unit_name,
            "lock": 0,
            "ttl": "",
          };
          this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Verileriniz hazırlanıyor. Lütfen bekleyiniz.' } });
          WmanagerService.db_get_one_data(data)
            .then(resp => {
              this.$store.commit('Loading', { 'status': false, 'data': {} });
              if (resp.data.status_code === '3000') {
                this.d_searchingData.data = resp.data.data;
                if (this.d_searchingData.download) {
                  let json_file = JSON.stringify(this.d_searchingData.data, null, 4);
                  let download_data = [json_file];
                  var blob = new Blob(download_data, { type: "text/plain;charset=utf-8" });
                  FileSaver.saveAs(blob, "document.json");
                }
              } else {
                alert('error: ' + resp.data.status_message);
              }
            });
        }
      }
    },
    f_upsertData: function () {
      if (this.d_editData.data && this.d_editData.unit_name && this.d_editData.key) {
        let data = {
          "key": this.d_editData.key,
          "value": JSON.parse(this.d_editData.data),
          "unit": this.d_editData.unit_name,
        };
        this.d_searchingData.unit_name = this.d_editData.unit_name;
        this.d_searchingData.key = this.d_editData.key;
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Verileriniz kaydediliyor. Lütfen bekleyiniz.' } });
        WmanagerService.db_upsert_one_data(data)
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            if (resp.data.status_code === '3000') {
              this.d_editData.show = false;
              this.f_getSearchList();
            } else {
              alert('error: ' + resp.data.status_message);
            }
          });
      }
    },
  },
  components: {
    Modal,
  },
  watch: {}
};

</script>

<style type="text/css">
.s_tdCls {
  white-space: nowrap;
}

.s_approvalNotSelected {
  background-color: white;
  cursor: pointer;
  color: black;
  border-bottom: solid 0.5px #00000075;
}

.s_approvalNotSelected:hover {
  background-color: #e6e6e6;
  cursor: pointer;
  color: black;
  border-bottom: solid 0.5px #00000075;
  /*border-bottom: solid 1px #e6e6e6;*/
}

.s_approvalSelected {
  background-color: #9effc08f;
  cursor: pointer;
  color: black;
  border-bottom: solid 0.5px #00000075;
}

.s_approvalSelected:hover {
  background-color: #e6e6e6;
  cursor: pointer;
  color: black;
  border-bottom: solid 0.5px #00000075;
}

#s_approvalTypesScrollbar::-webkit-scrollbar-track {
  border-radius: 0 10px 10px 0;
  background-color: #d9e9ea;
}

#s_approvalTypesScrollbar::-webkit-scrollbar {
  width: 10px;
  /*background-color: #F5F5F5;*/
}

#s_approvalTypesScrollbar::-webkit-scrollbar-thumb {
  border-radius: 0 10px 10px 0;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #4788ac;
}

</style>

